<template>
  <v-form @submit.prevent="doLogin" ref="loginForm">
    <div>
      <v-row :class="{ 'mt-3': site }">
        <v-col cols="12" :class="{ 'pb-6': site, 'pb-1': !site }">
          <div class="h-50">
            <v-text-field
              :label="$_t('email')"
              :class="{ 'b-black no-error-msg': site }"
              color="SonicSilver"
              v-model="form.username"
              autocomplete="username"
              outlined
              dense
              :rules="emailValidation"
            />
          </div>
        </v-col>
        <v-col cols="12" class="pt-0">
          <div class="h-50">
            <v-text-field
              :class="['pt-0', ' mt-0', { 'b-black': site }]"
              :label="$_t('pass')"
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :append-icon="showPassword ? 'WMi-eye-off' : 'WMi-eye'"
              autocomplete="current-password"
              color="SonicSilver"
              outlined
              dense
              :rules="requiredPassword"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <VueRecaptcha
                  :sitekey="sitekey"
                  ref="recaptcha"
                  @verify="handleSuccess"
                  @error="handleError"
                  @expired="handleExpired"
          />
        </v-col>
      </v-row>
      <v-row class="pt-2" :class="{ 'mt-0': site }">
        <v-col cols="6" class="wa__tile--link">
          <router-link
            :to="{
              name: 'register',
              params: { locale: this.$_getlocaleParam() },
            }"
          >
            <block-button
              height="30"
              class="btn-signup float-left"
              :text="$_t('signUp')"
              icon="WMi-plus-linear"
              text-mode="text"
            />
          </router-link>
        </v-col>
        <v-col cols="6" class="wa__tile--link">
          <block-button
            v-if="!site"
            height="30"
            class="
              btn-login
              site__button
              width-auto
              bg-transparent
              float-right float-xl-none
            "
            :text="$_t('login')"
            :loading="loadingRequest"
            type="submit"
          />
          <button
            v-else
            class="
              site__button
              width-auto
              bg-transparent
              float-right float-xl-none
              w-100
            "
          >
            {{ $_t("login") }}
          </button>
        </v-col>
      </v-row>
      <v-row :class="{ 'mb-16': !site }">
        <v-col cols="12" class="wa__tile--link text-center">
          <router-link
            :to="{
              name: 'passwordReset',
              params: { locale: this.$_getlocaleParam() },
            }"
          >
            <block-button
              :text="$_t('forgot')"
              height="30"
              text-mode="text"
              class="btn-forgot w-100"
            />
          </router-link>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>
<script>
import {emailValidation,requiredPassword} from "@/mixins/validate"
import { mapActions,mapMutations } from "vuex";
import { VueRecaptcha } from 'vue-recaptcha';
import Vue from 'vue';
export default {
  components:{
    VueRecaptcha
  },
  data: () => ({
    inputColor: "#acacac",
    form: {},
    sitekey: Vue.prototype.$recaptchaSiteKey,
    verify: false,
    showPassword: false,
    loadingRequest: false,
  }),
  props: {
    site: {
      default: false,
    },
  },
  computed:{
    emailValidation,
    requiredPassword
  },
  methods: {
    ...mapMutations('user', ['SET_MY_INFO']),
    ...mapActions("auth", ["login"]),
    async doLogin() {
      try {
        if (!this.$refs.loginForm.validate()) {
          return;
        }

        if (this.verify) {
          this.loadingRequest = true;
          let redirect = this.$route.query.redirect;
          const response = await this.login(this.form);
          if (response) {
            this.SET_MY_INFO(response.user);
            if (redirect && redirect !== "/") {
              this.$router.push(redirect);
            } else {
              this.$router.push({
                name: "dashboard",
                params: { locale: this.$_getlocaleParam() },
              });
            }
          }
        }
      } catch (e) {
        return e;
      } finally {
        this.loadingRequest = false;
      }
    },
    handleSuccess() {
      this.verify = true;
    },
    handleExpired() {
      this.verify = false;
    },
    handleError() {
      this.verify = false;
    }
  },
  created() {
    console.log(this.$recaptchaSiteKey)
  }
};
</script>
<style scoped>
.btn-login {
  font-size: 13px !important;
  padding: 0 70px !important;
  letter-spacing: 3px !important;
}
.btn-signup {
  font-size: 14px !important;
  letter-spacing: 3px !important;
}
.btn-forgot {
  letter-spacing: 3px !important;
  font-size: 14px !important;
}
.h-50 {
  height: 50px;
}
.wa__tile--link a {
  text-decoration: none;
  display: block;
  color: initial;
}
.site__login__button {
  border: 1px solid var(--color-black);
  transition: all 0.5s;
}
.site__login__button:hover {
  background-color: var(--color-black);
  color: var(--color-white);
}
@media screen and (max-width: 600px) {
  .btn-login {
    font-size: 12px !important;
    padding: 0 60px !important;
    width: -webkit-fill-available;
    width: 100% !important;
    letter-spacing: 2px !important;
  }
}
</style>
